/**************************************************
 * Nombre:       Contactos
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid, Typography, useMediaQuery} from "@mui/material";
import fondo from '../../../Recursos/natural-paper.png'
import logo from '../../../Recursos/fondo_contactos.png'
import {Parallax} from 'react-parallax';
import {ACENTO, CONTACTOS, PRIMARIO} from "../../../Constantes";
import '../../../Recursos/CitrusGothic-Regular.otf'
import {Calendar, Call, Shop, Sms} from "iconsax-react";
import {irUrl} from "../../../Utilidades/irUrl";
import {theme} from "../../../Tema";
import logoMobil from '../../../Recursos/fondo_contactos_mobil.png'


const Contactos = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const sTab = useMediaQuery(theme.breakpoints.only("sm"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))

    return (
        <Grid
            component={"div"}
            id={"Contactos"}
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{backgroundColor: '#F0EFD8', backgroundImage: `url(${fondo})`, backgroundRepeat: 'repeat', zIndex: 2}}
        >

            <Grid item lg={12} sm={12} xs={12}   >


                <Parallax bgImage={sCell ? logoMobil : logo} bgImageAlt="equipo"
                          bgImageStyle={{width: "100%"}} title={"equipo"}
                          lazy={true}
                          strength={200}
                          style={{zIndex: 2}}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                    >

                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            sx={{marginTop: 10, marginBottom: 18, maxWidth: '1400px', px: 3,}}
                            spacing={4}
                        >


                            <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: "center"}}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                >

                                    <Grid item lg={12} sm={12} xs={12}>
                                        <Typography
                                            sx={{
                                                color: PRIMARIO,
                                                fontWeight: 800,
                                                fontSize: 18,
                                            }}>Contactos</Typography>
                                    </Grid>


                                    <Grid item lg={12} sm={12} xs={12} sx={{marginTop: -1}}>
                                        <Typography sx={{
                                            color: ACENTO,
                                            fontWeight: 800,
                                            fontSize: 38,
                                            fontFamily: 'CitrusGothic-Regular',

                                        }}>PASTO - NARIño</Typography>
                                    </Grid>

                                    <Grid item lg={12} sm={12} xs={12} sx={{marginTop: 2}}>
                                        <iframe
                                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4324.1467793965585!2d-77.28156267160887!3d1.2142143615758347!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e2ed485b68a2e3d%3A0xa0b7bf79ce0acdc5!2sPicanter%C3%ADa%20Ipiales!5e0!3m2!1ses!2sco!4v1690837195221!5m2!1ses!2sco"
                                            width={"100%"} height={"370"} loading={"lazy"}
                                            style={{
                                                border: 0,
                                                borderRadius: 10,
                                                boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)"
                                            }}
                                            referrerPolicy="no-referrer-when-downgrade"></iframe>
                                    </Grid>


                                </Grid>
                            </Grid>

                            <Grid item lg={3} sm={12} xs={12} sx={{marginTop: masSM ? 10 : 4}}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    {CONTACTOS.map((item, index) => {
                                        return (
                                            <Grid item lg={12} sm={4} xs={12}>


                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="center"
                                                    onClick={() => index < 3 && irUrl(item.url)}
                                                    sx={{cursor: index < 3 && 'pointer'}}
                                                >

                                                    <Grid item lg={2} sm={2} xs={2}
                                                          sx={{filter: 'drop-shadow( 0px 3px 6px rgba(0, 0, 0, .4))'}}>
                                                        <item.Icono variant={'Bulk'} color={PRIMARIO} size={'2.5rem'}/>
                                                    </Grid>
                                                    <Grid item lg={10} sm={10} xs={10}>
                                                        <Typography sx={{
                                                            fontWeight: 600,
                                                            color: ACENTO,
                                                            fontSize: 14,
                                                            lineHeight: 1.2
                                                        }}>{item.contenido}</Typography>
                                                    </Grid>


                                                </Grid>
                                            </Grid>

                                        )
                                    })}

                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>

                </Parallax>

            </Grid>

        </Grid>
    )
}

export default Contactos;


