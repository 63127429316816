export const iniciarChatWhatsApp = ({num, nombre = " ", tema = " "}) => {


    const text = `https://api.whatsapp.com/send?phone=57${num}&text=Hola%20${procesarTexto(nombre)}quiero%20saber%20mas%20de%3A%0A${procesarTexto(tema)}`

    window.open(text)

}

const procesarTexto = (text) => {

    if (text === "") {
        return text
    } else {
        let noEspacios = text.replaceAll(" ", "%20")
        return noEspacios
    }


}