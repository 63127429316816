/**************************************************
 * Nombre:       Inicio
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Button, Grid, Typography, useMediaQuery} from "@mui/material";
import '../../../Recursos/CitrusGothic-Regular.otf'
import {COLORSUAVE, LINKMAP, REDES} from "../../../Constantes";
import lapingacho from '../../../Recursos/lapingacho_picanteria_ipiales.png'
import estrella from '../../../Recursos/estrella.svg'
import fondo from '../../../Recursos/fondo_las_lajas.png'
import '../../../Recursos/CitrusGothic-Regular.otf'
import {motion, useScroll, useTransform} from "framer-motion";
import {irUrl} from "../../../Utilidades/irUrl";
import {theme} from "../../../Tema";
import {Location, Whatsapp} from "iconsax-react";



const Inicio = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const sTab = useMediaQuery(theme.breakpoints.only("sm"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const speed = 1 / 2;
    const {scrollY} = useScroll();
    const x = useTransform(scrollY, (value) => -value * speed);


    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{
                backgroundColor: '#BA0008',
                backgroundImage: `url(${fondo})`,
                backgroundSize: "cover",
                backgroundRepeat: 'no-repeat'
            }}
        >

            <h1 style={{display: 'none'}}>Comida tradicional nariñence</h1>
            <h2 style={{display: 'none'}}>Tortilla de papa</h2>

            <Grid
                component={"div"}
                id={"Inicio"}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3, pt: 24}}
            >

                <Grid item lg={1} sm={12} xs={12}>

                </Grid>

                <Grid item lg={6} sm={6} xs={12} sx={{pb: 10}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >

                        <Grid item lg={12} sm={12} xs={12}>
                            <Typography sx={{
                                fontFamily: 'CitrusGothic-Regular',
                                fontSize: sCell ? 30 : 40,
                                color: COLORSUAVE,
                                lineHeight: 1.3,

                            }}>
                                LOS AUTENTICOS <span
                                style={{fontSize: sCell ? 60 : 70, color: '#fff'}}>LAPINGACHOS</span> <br/>
                                TRADICIÓN NARIÑENSE DE MAS DE{sCell ? " " : <br/>}
                                <span style={{fontSize: sCell ? 40 : 50, color: '#fff'}}>39 AÑOS</span>

                            </Typography>
                        </Grid>

                        <Grid item lg={10} sm={12} xs={12} sx={{marginTop: 2, mb: 8}}>
                            <Typography sx={{fontSize: 18, color: '#fff'}}>
                                Deliciosas tortillas de papa pastusa con queso cuajada preparadas a la plancha,
                                acompañadas con una selección de carnes y ensalada.
                            </Typography>
                        </Grid>

                        <Grid item container lg={5} sm={5} xs={6}
                              sx={{justifyContent: sCell ? 'center' : "flex-start"}}>
                            <Button
                                startIcon={<Whatsapp variant={'Bold'} />}
                                onClick={() => irUrl(REDES[2].url)}

                                sx={{px: sCell ? 3 : 10}}>Chatear</Button>
                        </Grid>

                        <Grid item lg={5} sm={5} xs={6} sx={{justifyContent: sCell ? 'center' : "flex-start"}} >
                            <Button
                                startIcon={<Location variant={'Bold'} />}
                                onClick={() => irUrl(LINKMAP)}
                                sx={{px: sCell ? 3 : 10}}>Dirigeme</Button>
                        </Grid>


                    </Grid>
                </Grid>

                <Grid item container lg={5} sm={6} xs={12}
                      sx={{
                          justifyContent: "flex-end",
                          marginTop: sCell ? -4 : 4,
                          marginBottom: -28,
                          marginRight: -14,
                          marginLeft: 14,
                          zIndex: 4,
                          filter: 'drop-shadow( 0px 3px 6px rgba(0, 0, 0, .4))'
                      }}>
                    {!sTab &&
                    <img src={lapingacho} width={sCell ? '90%' : '125%'} height={'auto'} alt={'Lapingacho'}
                         title={'Lapingacho picanteria Ipiales'}/>}
                </Grid>


            </Grid>

            <Grid
                component={motion.div}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{backgroundColor: '#000', py: 2, zIndex: 2, minWidth: 3500}}
                style={{x: x, transitionTimingFunction: "spring(1 100 10 10)", transition: "transform 3s"}}
            >

                {lista.map((item) => {
                    return (
                        <>
                            <Typography
                                sx={{
                                    color: '#fff',
                                    mx: 4,
                                    fontFamily: 'CitrusGothic-Regular',
                                    fontSize: 22
                                }}>{item}</Typography>
                            <img src={estrella} width={14} height={'auto'} alt={'estrella'} title={'Estrella'}
                                 style={{marginTop: -2}}/>
                        </>

                    )
                })}


            </Grid>


        </Grid>
    )
}

export default Inicio;

const lista = ["CON HORNADO", 'CON PECHUGA', 'CON CHORIZO', 'CON CARNE', 'CON PLATO MIXTO', 'CON MAIZ TOSTADO', "CON HORNADO", 'CON PECHUGA', 'CON CHORIZO', 'CON CARNE', 'CON PLATO MIXTO', 'CON MAIZ TOSTADO', "CON HORNADO", 'CON PECHUGA', 'CON CHORIZO', 'CON CARNE', 'CON PLATO MIXTO', 'CON MAIZ TOSTADO']