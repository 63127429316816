/**************************************************
 * Nombre:       Tarjeta_Cifra
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid, Typography, useMediaQuery} from "@mui/material";
import {Award} from "iconsax-react";
import {PRIMARIO} from "../../Constantes";
import {theme} from "../../Tema";


const Tarjeta_Cifra = ({item}) => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const sTab = useMediaQuery(theme.breakpoints.only("sm"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                <item.Icono color={'#fff'} size={ sCell ? '3rem' : '4rem'} variant={'Linear'}/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", mr: -3}}>
                <Typography sx={{
                    fontSize: sCell ? 32 : 45,
                    fontFamily: 'CitrusGothic-Regular',
                    color: PRIMARIO
                }}>{item.numero + '+'}</Typography>
            </Grid>

            <Grid item container lg={6} sm={12} xs={12}
                  sx={{justifyContent: "center", marginTop: -1, mr: sCell ? -0.5 : -1}}>
                <Typography sx={{
                    fontSize: sCell ? 14 : 16,
                    color: '#fff',
                    textAlign: 'center',
                    fontWeight: 600
                }}>{item.titulo}</Typography>
            </Grid>

        </Grid>
    )
}

export default Tarjeta_Cifra;