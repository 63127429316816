/**************************************************
 * Nombre:       Equipo
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid, Typography, useMediaQuery} from "@mui/material";
import fondo from "../../../Recursos/fondo_equipo.png";
import fondo_mobil from "../../../Recursos/fondo_equipo_mobil.png";
import {Parallax} from 'react-parallax';
import ceparador from '../../../Recursos/ceparador.svg'
import {ACENTO, PRIMARIO} from "../../../Constantes";
import {Award, Calendar, Calendar1, Happyemoji} from "iconsax-react";
import Tarjeta_Cifra from "../../Tarjetas/Tarjeta_Cifra";
import {theme} from "../../../Tema";

const Equipo = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const sTab = useMediaQuery(theme.breakpoints.only("sm"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{zIndex: 4}}
        >


            <Grid item lg={12} sm={12} xs={12}>
                <Parallax bgImage={sCell ? fondo_mobil : fondo} bgImageAlt="equipo"
                          bgImageStyle={{width: "100%"}}
                          title={"equipo"}
                          lazy={true}
                          strength={200}
                >

                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                    >

                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                            sx={{maxWidth: '1400px', px: 3, zIndex: 4}}
                        >


                            <Grid item container lg={12} sm={12} xs={12}
                                  sx={{justifyContent: "center", marginTop: sCell ? 14 : 10}}>
                                <Typography sx={{fontSize: 18, color: '#fff', textAlign: 'center'}}>
                                    Tradición Nariñence</Typography>
                            </Grid>

                            <Grid item container lg={12} sm={12} xs={12}
                                  sx={{justifyContent: "center", marginTop: sCell ? 1 : -1}}>
                                <Typography sx={{
                                    fontSize: sCell ? 42 : 50,
                                    fontFamily: 'CitrusGothic-Regular',
                                    color: PRIMARIO,
                                    textAlign: 'center',
                                    lineHeight: 1.1
                                }}>Déjanos atenderte</Typography>
                            </Grid>

                            <Grid item container lg={7} sm={12} xs={12}
                                  sx={{justifyContent: "center", marginTop: 26, marginBottom: 4}}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                >

                                    <Grid item container lg={3} sm={3} xs={3} sx={{justifyContent: "center"}}>
                                        <Tarjeta_Cifra item={cifras[0]}/>
                                    </Grid>

                                    <Grid item container lg={1} sm={1} xs={1} sx={{justifyContent: "center"}}>
                                        <img src={ceparador} width={18} height={'auto'}/>
                                    </Grid>

                                    <Grid item container lg={3} sm={3} xs={3} sx={{justifyContent: "center"}}>
                                        <Tarjeta_Cifra item={cifras[1]}/>
                                    </Grid>

                                    <Grid item container lg={1} sm={1} xs={1} sx={{justifyContent: "center"}}>
                                        <img src={ceparador} width={18} height={'auto'}/>
                                    </Grid>

                                    <Grid item container lg={3} sm={3} xs={3} sx={{justifyContent: "center"}}>
                                        <Tarjeta_Cifra item={cifras[2]}/>
                                    </Grid>


                                </Grid>
                            </Grid>


                        </Grid>


                    </Grid>


                </Parallax>


            </Grid>


        </Grid>
    )
}

export default Equipo;

const cifras = [
    {
        titulo: 'Calificacion Reseñas',
        Icono: Award,
        numero: 4.5
    },
    {
        titulo: 'Años de Tradcidicion',
        Icono: Calendar1,
        numero: 40
    },
    {
        titulo: 'Clientes Felices',
        Icono: Happyemoji,
        numero: 2500
    }
]