/**************************************************
 * Nombre:       Barra
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {ButtonBase, Grid, Typography} from "@mui/material";
import logo from '../Recursos/logoblanco.png'
import {REDES} from "../Constantes";
import {irUrl} from "../Utilidades/irUrl";


const Barra = ({secciones}) => {

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >


            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{maxWidth: '1400px', px: 10, marginBottom: -24, zIndex: 6, py: 2}}
            >

                <Grid item lg={2} sm={2} xs={12}>
                    <img src={logo} width={120} height={'auto'} alt={'Logo Picanteria Ipiales'}
                         title={'Logo Picanteria Ipiales'}/>
                </Grid>

                <Grid item lg={8} sm={8} xs={12}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >
                        {secciones.map((item, index) => {
                            return (
                                <Grid container key={index} item lg={2} sm={2} xs={12} justifyContent="center">
                                    <ButtonBase
                                        href={'#' + item}

                                    >
                                        <Typography
                                            sx={{color: '#fff', fontWeight: 600, fontSize: 18}}>{item}</Typography>
                                    </ButtonBase>
                                </Grid>
                            )
                        })}


                    </Grid>
                </Grid>

                <Grid item container lg={2} sm={2} xs={12} sx={{justifyContent: "flex-end"}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="flex-start"
                    >

                        <Grid item container lg={10} sm={12} xs={12} sx={{justifyContent: "flex-end"}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >
                                {REDES.map((item, index) => {
                                    return (
                                        <Grid key={index} item lg={4} sm={4} xs={12}>
                                            <ButtonBase
                                                onClick={() => irUrl(item.url)}
                                                sx={{borderRadius: 2}}>
                                                <item.Icono size={'2.5rem'} variant={"Bold"} color={'#fff'}/>
                                            </ButtonBase>
                                        </Grid>
                                    )
                                })}


                            </Grid>
                        </Grid>


                    </Grid>
                </Grid>


            </Grid>

        </Grid>
    )
}

export default Barra;



