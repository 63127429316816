// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {getAuth} from 'firebase/auth'
import {getFirestore} from 'firebase/firestore'
import {getStorage} from 'firebase/storage'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAjebO1rLhuGBrqzFM1d4RBBksSJqhwqTs",
    authDomain: "paginawebpicanteria.firebaseapp.com",
    projectId: "paginawebpicanteria",
    storageBucket: "paginawebpicanteria.appspot.com",
    messagingSenderId: "559322644949",
    appId: "1:559322644949:web:4613a6130907e4a1463d5c",
    measurementId: "G-4JDPTZHN84"
};








// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

export const auth = getAuth(app)
export const fire = getFirestore(app)
export const storage = getStorage(app)