/**************************************************
 * Nombre:       Catalogo
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid, Typography, useMediaQuery} from "@mui/material";
import {COLORSUAVE, PRIMARIO} from "../../../Constantes";
import '../../../Recursos/CitrusGothic-Regular.otf'
import sello from '../../../Recursos/cello_lapingachos.svg'
import CatalogoMenu from "./SubComponentes/CatalogoMenu";
import lineas from '../../../Recursos/lineas.svg'
import {theme} from "../../../Tema";
import fondo from '../../../Recursos/fondo_menu.png'
import fondo_celular from '../../../Recursos/fondo_celular.png'
import {Parallax} from "react-parallax";


const Catalogo = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const sTab = useMediaQuery(theme.breakpoints.only("sm"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{
                backgroundColor: PRIMARIO, backgroundRepeat: 'repeat',
            }}
        >


            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: sCell ? 'center' : "flex-end", px: 8, mt: -12, zIndex: 8}}>
                <img src={sello} width={200} height={'auto'} alt={'sello lapingachos'} title={'sello lapingachos'}
                     style={{zIndex: 100}}/>
            </Grid>

            <Parallax bgImage={sCell ? fondo_celular : fondo} bgImageAlt="equipo"
                      bgImageStyle={{width: "100%"}} title={"equipo"}
                      lazy={true}
                      strength={200}
            >

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    sx={{width: '100%'}}
                >


                    <Grid
                        component={"div"}
                        id={"Menu"}
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                        sx={{maxWidth: '1400px', px: 3}}
                    >


                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 0}}>
                            <Typography sx={{
                                fontSize: sCell ? 40 : 50,
                                fontFamily: 'CitrusGothic-Regular',
                                color: COLORSUAVE
                            }}>lapingachos</Typography>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                            <Typography sx={{fontSize: sCell ? 16 : 18, color: '#fff', textAlign: 'center'}}>Exquisita
                                tortilla
                                de papa
                                pastusa con cuajada,{!sCell ? <br/> : ' '}
                                preparada a la plancha acompañada de ensalada y carne</Typography>
                        </Grid>

                        <Grid item container lg={11} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 8}}>
                            <CatalogoMenu/>
                        </Grid>

                    </Grid>

                </Grid>
            </Parallax>


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "flex-end", marginBottom: sCell ? 2 : 0}}>
                <img src={lineas} width={sCell ? 200 : 350} height={'auto'}/>
            </Grid>

        </Grid>
    )
}

export default Catalogo;