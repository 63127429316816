/**************************************************
 * Nombre:       CatalogoMenu
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useEffect, useState} from 'react';
import {Grid, Tab, Tabs, useMediaQuery} from "@mui/material";
import {Ho_ManejoTargetas} from "../../../../Modulo_Tarjetas/Hocs/Ho_ManejoTargetas";
import Tarjeta_Producto from "../../../Tarjetas/Tarjeta_Producto";
import '../../../../Recursos/CitrusGothic-Regular.otf'
import {theme} from "../../../../Tema";
import {CATEGORIAS} from "../../../../Constantes";
import useRecuperarColleccionListeningToda from "../../../../Servicios/BD/useRecuperarColleccionListeningToda";
import {where} from 'firebase/firestore'


const CatalogoMenu = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const sTab = useMediaQuery(theme.breakpoints.only("sm"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))
    const [categoria, setCategoria] = useState('lapingachos')
    const [wheres, setWheres] = useState([where('categoria', '==', categoria)])
    const {data, error} = useRecuperarColleccionListeningToda({col: 'productos', orden: 'nombre', wheres: wheres})
    const [productos, setProductos] = useState([])


    const cambio = (e, newValue) => {
        setCategoria(newValue)
    }


    useEffect(() => {

        setWheres([where('categoria', '==', categoria)])

    }, [categoria])
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >


            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                <Tabs indicatorColor={'secondary'}
                      textColor={'secondary'}
                      value={categoria}
                      onChange={cambio}
                      variant={'scrollable'}
                      scrollButtons={true}

                >
                    {CATEGORIAS.map((item, index) => {
                        return (
                            <Tab label={item} value={item}
                                 sx={{fontFamily: 'CitrusGothic-Regular', fontWeight: 700, fontSize: sCell ? 24 : 30}}/>
                        )
                    })}
                </Tabs>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 10}}>
                <Ho_ManejoTargetas Card={Tarjeta_Producto} data={data} lg={4} spacing={4}/>
            </Grid>


        </Grid>
    )
}

export default CatalogoMenu;


const prod = [
    {
        nombre: 'Lapingachos con hornado',
        descripcion: 'Plato sencillo de lapingachos acompañando de delicioso hornado *cerdo al horno y ensalada',
        precio: 29500,
        categoria: 'lapingachos',
        img: 'https://firebasestorage.googleapis.com/v0/b/picanteriaipiales-d1558.appspot.com/o/menu%2Fhornado.png?alt=media&token=94d34608-dfda-4b7e-9427-98f7307ba7ed'
    },
    {
        nombre: 'Lapingachos con carne',
        descripcion: 'Plato sencillo de lapingachos acompañando de carne de cerdo frita y ensalada',
        precio: 29500,
        categoria: 'lapingachos',
        img: 'https://firebasestorage.googleapis.com/v0/b/picanteriaipiales-d1558.appspot.com/o/menu%2Fcarne.png?alt=media&token=de16ed6a-9d96-4aba-8e45-25c2587b2a00'
    },
    {
        nombre: 'Lapingachos con pechuga',
        descripcion: 'Plato sencillo  de lapingachos acompañando de pechuag de pollo a la plancha y ensalada',
        precio: 31000,
        categoria: 'lapingachos',
        img: 'https://firebasestorage.googleapis.com/v0/b/picanteriaipiales-d1558.appspot.com/o/menu%2Fpollo.png?alt=media&token=e0223000-b07f-4ac9-96bb-92e966a08f91'
    },
    {
        nombre: 'Lapingachos con chorizo',
        descripcion: 'Plato sencillo de lapingachos acompañando de pechuag de pollo a la plancha y ensalada',
        precio: 26000,
        categoria: 'lapingachos',
        img: ''
    },
    {
        nombre: 'Lapingachos con maiz tostado',
        descripcion: 'Plato sencillo de lapingachos acompañando de maiz tosatado y ensalada',
        precio: 23000,
        categoria: 'lapingachos',
    },
    {
        nombre: 'Lapingachos mixto carne y hornado',
        descripcion: 'Plato de lapingachos acompañando de carne de cerdo, hornado (carne de cerdo al horno) y ensalada ',
        precio: 44500,
        categoria: 'mixtos',
    },
    {
        nombre: 'Lapingachos mixto carne y chorizo',
        descripcion: 'Plato de lapingachos acompañando de chorizo, carne de cerdo y ensalada ',
        precio: 42000,
        categoria: 'mixtos',
    },
    {
        nombre: 'Lapingachos mixto carne y pollo',
        descripcion: 'Plato de lapingachos acompañando de carne y pollo receta de la casa y ensalada ',
        precio: 45500,
        categoria: 'mixtos',
    },
    {
        nombre: 'Lapingachos mixto pollo y hornado',
        descripcion: 'Plato de lapingachos acompañando de pollo, hornado (carne de cerdo al horno) y ensalada ',
        precio: 45500,
        categoria: 'mixtos',
    },
    {
        nombre: 'Lapingachos mixto pollo y chorizo',
        descripcion: 'Plato de lapingachos acompañando de chorizo, pollo reseta de la casa y ensalada ',
        precio: 43500,
        categoria: 'mixtos',
    },
    {
        nombre: 'Lapingachos mixto chorizo y hornado',
        descripcion: 'Plato de lapingachos acompañando de chorizo, hornado (carne de cerdo al horno) y ensalada ',
        precio: 42000,
        categoria: 'mixtos',
    },
    {
        nombre: 'Menu Especial',
        descripcion: 'Lunes a Sabado, sopa del dia, plato de lapingachos con 1/2 chuleta de cerdo y bebida',
        precio: 21000,
        categoria: 'menu especial',
    },
    {
        nombre: 'Bandeja Especial',
        descripcion: 'Plato de lapingachos con 1/2 chuleta de cerdo y bebida',
        precio: 20000,
        categoria: 'menu especial',
    },
    {
        nombre: 'Sopa del dia',
        descripcion: 'Sopa del dia',
        precio: 7000,
        categoria: 'menu especial',
    },
    {
        nombre: 'Maiz Tostado',
        descripcion: 'Porcion personal de maiz tostado con platano frito',
        precio: 9800,
        categoria: 'entradas y adicionales',
        img: 'https://firebasestorage.googleapis.com/v0/b/picanteriaipiales-d1558.appspot.com/o/menu%2Fmaiz.png?alt=media&token=3ca2a7f6-c245-4062-a9fb-1919b4c2b304'
    },
    {
        nombre: 'Maiz Tostado',
        descripcion: 'Porcion mediana de maiz tostado con platano frito',
        precio: 19600,
        categoria: 'entradas y adicionales',
        img: 'https://firebasestorage.googleapis.com/v0/b/picanteriaipiales-d1558.appspot.com/o/menu%2Fmaiz.png?alt=media&token=3ca2a7f6-c245-4062-a9fb-1919b4c2b304'
    },
    {
        nombre: 'Maiz Tostado',
        descripcion: 'Caja familiar de maiz tostado con platano frito',
        precio: 39200,
        categoria: 'entradas y adicionales',
        img: 'https://firebasestorage.googleapis.com/v0/b/picanteriaipiales-d1558.appspot.com/o/menu%2Fmaiz.png?alt=media&token=3ca2a7f6-c245-4062-a9fb-1919b4c2b304'
    },
    {
        nombre: 'Porcion de lapingachos',
        descripcion: 'Porcion adcional de deliciosos lapingachos',
        precio: 14500,
        categoria: 'entradas y adicionales',
        img: ''
    },
    {
        nombre: 'Porcion de carne',
        descripcion: 'Porcion adcional de carne',
        precio: 14500,
        categoria: 'entradas y adicionales',
        img: ''
    },
    {
        nombre: 'Porcion de pechuga',
        descripcion: 'Porcion adcional de pechuga',
        precio: 22000,
        categoria: 'entradas y adicionales',
        img: ''
    },
    {
        nombre: 'Porcion de chorizo',
        descripcion: 'Porcion adcional de deliciosos chorizo',
        precio: 14000,
        categoria: 'entradas y adicionales',
        img: ''
    },
    {
        nombre: 'Porcion de hornado',
        descripcion: 'Porcion adcional de deliciosos hornado',
        precio: 18000,
        categoria: 'entradas y adicionales',
        img: ''
    },
    {
        nombre: 'Tortilla por unidad',
        descripcion: 'Tortilla de papa pastusa con cuajada',
        precio: 2600,
        categoria: 'entradas y adicionales',
        img: ''
    },
    //bebidas
    {
        nombre: 'Limonada Natural',
        descripcion: 'Preparada con limones frescos y un toque de sirope de menta o manzana verde',
        precio: 5000,
        categoria: 'bebidas',
        img: 'https://firebasestorage.googleapis.com/v0/b/picanteriaipiales-d1558.appspot.com/o/menu%2Flimonada_natural.png?alt=media&token=4f8b6080-2670-4489-b94c-87850da2a8ed'
    },
    {
        nombre: 'Limonada de Coco',
        descripcion: 'Preparada con autentica leche de coco',
        precio: 7000,
        categoria: 'bebidas',
        img: ''
    },
    {
        nombre: 'Limonada de Cerezada',
        descripcion: 'Preparada con frescas cerezas y un toque de sirope de frutos rojos',
        precio: 7000,
        categoria: 'bebidas',
        img: 'https://firebasestorage.googleapis.com/v0/b/picanteriaipiales-d1558.appspot.com/o/menu%2Flimonada_cerezada.png?alt=media&token=2187468f-3739-4a48-babe-87fe73c1f5bd'
    },
    {
        nombre: 'Limonanda Especial',
        descripcion: 'Preparada con cereza y coco',
        precio: 7000,
        categoria: 'bebidas',
        img: 'https://firebasestorage.googleapis.com/v0/b/picanteriaipiales-d1558.appspot.com/o/menu%2Flimonada_especial.png?alt=media&token=7bc85751-0586-449b-9cdc-a93d23990945'
    },
    {
        nombre: 'Jugo de Fruta 100% Natural',
        descripcion: 'Jugo tradicional de fruta fresca',
        precio: 5000,
        categoria: 'bebidas',
        img: 'https://firebasestorage.googleapis.com/v0/b/picanteriaipiales-d1558.appspot.com/o/menu%2Fjugos_fruta.png?alt=media&token=c94059c4-9fef-49f4-8ffb-b04ecda05b58'
    },
    {
        nombre: 'Café Expreso',
        descripcion: 'Café Expreso',
        precio: 3500,
        categoria: 'bebidas',
        img: ''
    },
    {
        nombre: 'Café Americano',
        descripcion: 'Café Americano',
        precio: 3500,
        categoria: 'bebidas',
        img: ''
    },
    {
        nombre: 'Café Expreso',
        descripcion: 'Café Expreso',
        precio: 3500,
        categoria: 'bebidas',
        img: ''
    },
    {
        nombre: 'Café Late',
        descripcion: 'Con adición de sirope de caramelo o vainilla $ 2000 adicional',
        precio: 3500,
        categoria: 'bebidas',
        img: ''
    },
    {
        nombre: 'Capuccino',
        descripcion: 'Con adición de sirope de caramelo o vainilla $ 2000 adicional, con adición de bayleis o amaretto $ 5000 adicional ',
        precio: 4500,
        categoria: 'bebidas',
        img: ''
    },
    {
        nombre: 'Mocaccino',
        descripcion: 'Mocaccino',
        precio: 4500,
        categoria: 'bebidas',
        img: ''
    },
    {
        nombre: 'Frapuccino',
        descripcion: '',
        precio: 7000,
        categoria: 'bebidas',
        img: ''
    },
    {
        nombre: 'Chocolate',
        descripcion: '',
        precio: 4000,
        categoria: 'bebidas',
        img: ''
    },
    {
        nombre: 'Aromatica de frutas',
        descripcion: '',
        precio: 4200,
        categoria: 'bebidas',
        img: ''
    },
    {
        nombre: 'Hervidos de frutas',
        descripcion: '',
        precio: 9000,
        categoria: 'bebidas',
        img: ''
    },

]