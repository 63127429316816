import {createContext, useEffect} from 'react';
import './App.css';
import {Grid, } from "@mui/material";
import './Recursos/CitrusGothic-Regular.otf'
import Pagina from "./Pagina/Pagina";

export const usuarioContext = createContext();

function App() {
   /* const {email} = useEscucharAuth()
    const {usuario} = useEscucharUsuarioToCorreo({correo: email})

    const valoresProvider = {
        usuario,
    }

    const {Provider} = usuarioContext;


    useEffect(() => {
        iniciarAnaliticas()
        if (window.swUpdateReady) {
            window.swUpdateReady = false;
            window.stop();
            window.location.reload();
        }
    }, [])*/
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{maxWidth: '100vw', overflowX: 'hidden'}}
        >

            <Pagina/>

            {/*}  <Provider value={valoresProvider}>
                {usuario ?

                    <Dashboard/>
                    :

                    <Pagina/>


                }

            </Provider>*/}


        </Grid>
    );
}

export default App;
