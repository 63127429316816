import {Calendar, Call, Facebook, Instagram, Shop, Sms, Whatsapp} from "iconsax-react";

export const COLORSUAVE = '#F0EFD8'
export const PRIMARIO = '#BA0008'
export const ACENTO = '#690001'
export const LINKMAP = "https://www.google.com/maps/dir/?api=1&destination=1.2139431008943895,-77.27756985304904,190&dir_action=navigate"

export const LINKPARQUEADERO = "https://www.google.com/maps/dir/?api=1&destination=1.213643817224039,-77.27752260251346&dir_action=navigate"
export const NUMWASS = "3016558462"

export const CATEGORIAS = ['lapingachos', 'mixtos', 'entradas y adicionales', 'bebidas', 'menu especial']

export const CONTACTOS = [
    {
        nombre: 'Direccion',
        contenido: "Calle 19 # 23 37 Centro - Pasto",
        Icono: Shop,
        url: LINKMAP
    },

    {
        nombre: 'Telefonos',
        contenido: "304 411 1144  -  301 655 8462",
        Icono: Call,
        url: 'https://api.whatsapp.com/send?phone=573016558462&text=Hola%2C%20quiero%20saber%20mas%20de%20Picanteria%20Ipiales'
    },

    {
        nombre: 'Correo',
        contenido: "picanteriapasto@gmail.com",
        Icono: Sms,
        url: '`mailto:${picanteriapasto@gmail.com}?subject=Envio Hoja de vida`'
    },

    {
        nombre: 'Horarios',
        contenido: "Lunes a Sábado 10 AM a 8 PM\n" +
            "Dom y festivos de 12 PM a 5 PM",
        Icono: Calendar,
        url: ''
    },
]


export const REDES = [
    {
        nombre: 'facebook',
        Icono: Facebook,
        url: 'https://www.facebook.com/PicanteriaIpiales'
    },
    {
        nombre: 'instagram',
        Icono: Instagram,
        url: 'https://www.instagram.com/picanteriaipiales/'
    },
    {
        nombre: 'wass',
        Icono: Whatsapp,
        url: 'https://api.whatsapp.com/send?phone=573016558462&text=Hola%2C%20quiero%20saber%20mas%20de%20Picanteria%20Ipiales'
    },
]