/**************************************************
 * Nombre:       Testimonios
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Button, Grid, Typography, useMediaQuery} from "@mui/material";
import fondo from "../../../Recursos/natural-paper.png";
import fondo_card from "../../../Recursos/concrete-wall-2.png";
import {ACENTO, COLORSUAVE, PRIMARIO} from "../../../Constantes";
import {Splide, SplideSlide} from "@splidejs/react-splide";
import '@splidejs/react-splide/css';
import Tarjeta_Testimonio from "../../Tarjetas/Tarjeta_Testimonio";
import carita1 from "../../../Recursos/carita1.svg";
import carita2 from "../../../Recursos/carita2.svg";
import {theme} from "../../../Tema";


const Testimonios = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const sTab = useMediaQuery(theme.breakpoints.only("sm"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{backgroundColor: '#F0EFD8', backgroundImage: `url(${fondo})`, backgroundRepeat: 'repeat', zIndex: 2}}
        >

            <Grid
                component={"div"}
                id={"Testimonios"}
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3, zIndex: 2}}
            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: sCell ? 8 : 14}}>
                    <Typography sx={{fontSize: 18, color: PRIMARIO, textAlign: 'center'}}>Nuestros clientes gritan
                        Tradición y Lapingachos</Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: sCell ? 1 : -1}}>
                    <Typography sx={{
                        fontSize: sCell ? 40 : 50,
                        fontFamily: 'CitrusGothic-Regular',
                        color: ACENTO,
                        textAlign: 'center',
                        lineHeight: 1.1
                    }}>Testimonios y Reseñas</Typography>
                </Grid>


                <Grid item lg={9} sm={12} xs={12} sx={{marginTop: 8}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >

                        <Grid item container lg={1} sm={12} xs={2} sx={{justifyContent: "flex-end"}}>
                            <img src={carita1} width={80} height={'auto'}/>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 1}}>
                            <Splide aria-label="My Favorite Images" style={{width: "100%"}}
                                    options={{
                                        autoplay: true,
                                        speed: 3000,
                                        rewind: true,
                                        perPage: 1,
                                        arrows: true,
                                        gap: 10,
                                        focus: 'center'


                                    }}
                            >
                                {test.map((item, index) => {
                                    return (
                                        <SplideSlide>
                                            <Tarjeta_Testimonio item={item}/>
                                        </SplideSlide>
                                    )
                                })}


                            </Splide>
                        </Grid>

                        <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "flex-end"}}>
                            <img src={carita2} width={80} height={'auto'}/>
                        </Grid>

                    </Grid>


                </Grid>

                <Grid item container lg={12} sm={12} xs={12}
                      sx={{justifyContent: "center", marginTop: 2, marginBottom: sCell ? 16 : 12}}>
                    {/* <Button color={'primary'} sx={{color: '#fff'}}>Deja tu testimonio</Button>*/}
                </Grid>

            </Grid>


        </Grid>
    )
}

export default Testimonios;


const test = [
    {
        nombre: 'Wilfred Virguez',
        descripcion: 'Muuuy rico los lapingachos. La carne es húmeda, tiene muy buen sabor y el acompañamiento es excelente complemento',
        img: 'https://firebasestorage.googleapis.com/v0/b/picanteriaipiales-d1558.appspot.com/o/testimonios%2Fwilfred.png?alt=media&token=c88d9c24-5191-4f70-af91-7d2d8d5795ba'
    },
    {
        nombre: 'Lorena Martinez',
        descripcion: 'La comida es muy rica, los lapingachos son deliciosos! Pruébelo! El Servicio fue rápido, el lugar es lindo y muy agradable. La limonada muy rica aunque no está en la carta.',
        img: 'https://firebasestorage.googleapis.com/v0/b/picanteriaipiales-d1558.appspot.com/o/testimonios%2Ftestiuno.png?alt=media&token=fd736157-6d23-4499-b76f-ca0981c0b22c'
    }
]