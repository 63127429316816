/**************************************************
 * Nombre:       Home
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid, useMediaQuery} from "@mui/material";
import Inicio from "./Componentes/Inicio";
import Contactos from "./Componentes/Contactos";
import Catalogo from "./Componentes/Catalogo";
import Testimonios from "./Componentes/Testimonios";
import Equipo from "./Componentes/Equipo";
import sello from "../../Recursos/cello_lapingachos.svg";
import Footer from "../Footer";
import {theme} from "../../Tema";
import Parqueadero_Gratuito from "./Componentes/Parqueadero_Gratuito";


const Home = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const sTab = useMediaQuery(theme.breakpoints.only("sm"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >

            <Grid item lg={12} sm={12} xs={12}>
                <Inicio/>
            </Grid>

            <Grid item lg={12} sm={12} xs={12} sx={{zIndex: 2}}>
                <Contactos/>
            </Grid>

            <Grid item lg={12} sm={12} xs={12} sx={{zIndex: 10}}>
                <Catalogo/>
            </Grid>

            <Grid item lg={12} sm={12} xs={12} sx={{zIndex: 2}}>
                <Testimonios/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'flex-start', marginTop: sCell ? -6 : 0, zIndex: 3}}>
                <Parqueadero_Gratuito/>
            </Grid>

            <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", zIndex: 4}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    sx={{maxWidth: '1400px', px: 3}}
                >
                    <Grid container item lg={12} sm={12} xs={12}
                          sx={{
                              marginTop: sCell ? -12 : -18,
                              marginBottom: sCell ? -12 : -18,
                              justifyContent: sCell ? 'center' : 'flex-start'
                          }}>
                        <img src={sello} width={sCell ? 180 : 250} height={'auto'} alt={'sello lapingachos'}
                             title={'sello lapingachos'}/>
                    </Grid>


                </Grid>
            </Grid>

            <Grid item lg={12} sm={12} xs={12}>
                <Equipo/>
            </Grid>

            <Grid item lg={12} sm={12} xs={12}>
                <Footer/>
            </Grid>

        </Grid>
    )
}

export default Home;