/**************************************************
 * Nombre:       Tarjeta_Testimonio
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid, Typography, useMediaQuery} from "@mui/material";
import carita1 from "../../Recursos/carita1.svg";
import fondo_card from "../../Recursos/concrete-wall-2.png";
import foto from "../../Recursos/foto_testimonio.png";
import comillas from "../../Recursos/comillas.svg";
import {PRIMARIO} from "../../Constantes";
import comillas2 from "../../Recursos/comillas2.svg";
import carita2 from "../../Recursos/carita2.svg";
import {theme} from "../../Tema";


const Tarjeta_Testimonio = ({item}) => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const sTab = useMediaQuery(theme.breakpoints.only("sm"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))


    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{py: 4, pr: sCell ? 0 : 8, pl: sCell ? 4 : 12}}
        >

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{
                    backgroundColor: '#000',
                    borderRadius: 8,
                    px: 2,
                    pt: 3,
                    pb: 6,
                    backgroundImage: `url(${fondo_card})`,
                    boxShadow: 4
                }}
                spacing={4}
            >

                <Grid item container lg={5} sm={5} xs={12}
                      sx={{justifyContent: "center", ml: sCell ? -2 : 0}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        sx={{
                            backgroundImage: `url(${item.img})`,
                            width: '90%',
                            height: 170,
                            backgroundSize: 'cover',
                            borderRadius: 6,
                            transform: 'rotate(-8deg)',
                            boxShadow: 10
                        }}
                    >


                    </Grid>

                </Grid>


                <Grid item lg={7} sm={7} xs={12} sx={{pr: 3}}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >

                        <Grid item lg={12} sm={12} xs={12}>
                            <img src={comillas} width={30} height={'auto'}/>
                        </Grid>

                        <Grid item lg={12} sm={12} xs={12} sx={{m: 2}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >
                                <Grid item lg={12} sm={12} xs={12}>

                                </Grid>

                                <Grid item lg={12} sm={12} xs={12} sx={{mb: 2}}>
                                    <Typography sx={{color: '#fff', fontWeight: 500}}>
                                        {item.descripcion}
                                    </Typography>
                                </Grid>

                                <Grid item lg={6} sm={8} xs={8}>
                                    <Typography sx={{
                                        fontSize: 22,
                                        fontFamily: 'CitrusGothic-Regular',
                                        color: PRIMARIO
                                    }}>{item.nombre}</Typography>
                                </Grid>

                                <Grid item lg={6} sm={4} xs={4}>
                                    <Typography sx={{
                                        fontSize: 22,
                                        fontFamily: 'CitrusGothic-Regular',
                                        color: '#fff'
                                    }}>MAP</Typography>
                                </Grid>

                            </Grid>
                        </Grid>


                        <Grid item container lg={12} sm={12} xs={12}
                              sx={{justifyContent: "flex-end", marginTop: -2}}>
                            <img src={comillas2} width={30} height={'auto'}/>
                        </Grid>

                    </Grid>
                </Grid>

            </Grid>


        </Grid>
    )
}

export default Tarjeta_Testimonio;