/**************************************************
 * Nombre:       BarraHamburguer
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useState} from 'react';
import {Button, ButtonBase, Drawer, Grid, Typography} from "@mui/material";
import {CloseSquare, HambergerMenu, Location} from "iconsax-react";
import {irUrl} from "../../../Utilidades/irUrl";
import {COLORSUAVE, LINKMAP, REDES} from "../../../Constantes";
import logo from '../../../Recursos/logoblanco.png'


const BarraHamburguer = ({secciones}) => {
    const [open, setOpen] = useState(false)

    const abrir = () => {
        setOpen(true)
    }

    const cerrar = () => {
        setOpen(false)
        let hash = window.location.hash
        if (!hash) {
            return
        }
        setTimeout(() => {

            let hash = window.location.hash
            let seccion = hash.replaceAll('#', '')
            // marcarEvento('seccion_' + seccion)
            let compo = window.document.getElementById(seccion)
            compo.scrollIntoView({
                behavior: 'smooth', // smooth scroll
                block: 'start' // the upper border of the element will be aligned at the top of the visible part of the window of the scrollable area.
            })
        }, 500)


    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{marginBottom: -15, zIndex: 4, p: 2}}
        >

            <Grid item lg={6} sm={6} xs={6}>
                <img src={logo} width={80} height={'auto'} alt={"Logo"} title={"Logo"}/>
            </Grid>

            <Grid item container lg={6} sm={6} xs={6} sx={{justifyContent: "flex-end"}}>
                <ButtonBase
                    onClick={() => abrir()}
                    sx={{p: 1, borderRadius: 1}}>
                    <HambergerMenu color={'#fff'} size={'2rem'}/>
                </ButtonBase>
            </Grid>


            <Drawer open={open} onClose={cerrar}
                    anchor={"right"}

            >
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    sx={{width: 250, p: 3}}
                >


                    <Grid item lg={12} sm={12} xs={12}>
                        <ButtonBase onClick={() => cerrar()}>
                            <CloseSquare color={"#fff"} size={"2rem"}/>
                        </ButtonBase>
                    </Grid>


                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >
                            {secciones.map((item, index) => {
                                return (
                                    <Grid
                                        component={"div"}
                                        data-aos="fade-right"
                                        data-aos-delay={200 * index}
                                        data-aos-duration="1500"
                                        key={index}
                                        item container lg={12} sm={12} xs={12}
                                        sx={{justifyContent: "center", marginTop: 2}}>
                                        <ButtonBase
                                            href={"#" + item}
                                            sx={{
                                                //borderBottom: x === item ? 2 : 0,
                                                borderColor: "#FF6623", py: 0.5
                                            }}>
                                            <Typography
                                                //onClick={() => setX(item)}
                                                sx={{
                                                    color: COLORSUAVE,
                                                    fontFamily: "Montserrat",
                                                    fontSize: 20,
                                                    fontWeight: 700,
                                                    "&:hover": {color: "#fff"},

                                                }}>{item}</Typography>
                                        </ButtonBase>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>


                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 8}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                        >

                            {REDES.map((item) => {

                                const irRed = () => {
                                    irUrl(item.url)
                                    //  marcarEvento('red_' + item.nombre)
                                }


                                return (
                                    <Grid item container lg={12} sm={12} xs={4} sx={{justifyContent: "center"}}>
                                        <ButtonBase
                                            onClick={() => irRed()}
                                        >
                                            <item.Icono color={"#fff"} variant={"Bulk"} size={"3rem"}/>
                                        </ButtonBase>
                                    </Grid>
                                )
                            })}

                        </Grid>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 6}}>
                        <Button
                            onClick={() => irUrl(LINKMAP)}
                            startIcon={<Location/>} color={"action"}
                            sx={{color: "#000"}}
                        >Llevame</Button>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 20}}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                        >

                            <Grid item container lg={12} sm={12} xs={12}
                                  sx={{justifyContent: "center", marginTop: 5}}>
                                <img src={logo} width={"90%"} height={"auto"}
                                     alt={"Logo international logistics MD"} title={"Logo international logistics MD"}/>
                            </Grid>


                        </Grid>
                    </Grid>

                </Grid>
            </Drawer>

        </Grid>
    )
}

export default BarraHamburguer;