/**************************************************
 * Nombre:       Footer
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Button, ButtonBase, Grid, Typography, useMediaQuery} from "@mui/material";
import {COLORSUAVE, CONTACTOS, LINKMAP, NUMWASS, PRIMARIO, REDES} from "../Constantes";
import logo from '../Recursos/logoblanco.png'
import {irUrl} from "../Utilidades/irUrl";
import {theme} from "../Tema";
import {Whatsapp} from "iconsax-react";


const Footer = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const sTab = useMediaQuery(theme.breakpoints.only("sm"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))


    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{backgroundColor: '#000'}}
        >


            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 6, pt: 10, pb: 3}}
            >


                <Grid item lg={5} sm={7} xs={12}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >

                        <Grid item lg={12} sm={12} xs={12}>
                            <Typography sx={{
                                fontFamily: 'CitrusGothic-Regular',
                                fontSize: sCell ? 34 : 40,
                                color: PRIMARIO,
                            }}>
                                pasto - Nariño

                            </Typography>
                        </Grid>

                        {CONTACTOS.map((item, index) => {
                            return (
                                <Grid item lg={12} sm={12} xs={12}>
                                    <Typography sx={{
                                        fontSize: sCell ? 16 : 18,
                                        fontWeight: 500,
                                        color: '#fff',
                                    }}>
                                        {index < 3 && item.nombre + ' ' + item.contenido}

                                    </Typography>
                                </Grid>
                            )
                        })}

                        <Grid item lg={12} sm={12} xs={12}>
                            <ButtonBase
                                // onClick={() => abrir()}
                                sx={{px: 0}}>
                                <Typography
                                    sx={{
                                        fontSize: sCell ? 16 : 18,
                                        fontWeight: 500,
                                        color: '#fff',
                                    }}>
                                    {'Administración'}</Typography>
                            </ButtonBase>
                        </Grid>

                        <Grid item lg={6} sm={6} xs={12} sx={{mt: 6}}>
                            <Button
                                onClick={() => irUrl(REDES[2].url)}
                                startIcon={<Whatsapp variant={'Bold'}/>}
                                sx={{color: '#000'}}>Chatear</Button>
                        </Grid>

                        <Grid item lg={6} sm={6} xs={12} sx={{mt: sCell ? 3 : 6}}>
                            <Button
                                onClick={() => irUrl(LINKMAP)}
                                sx={{color: '#000'}}>Dirigeme</Button>
                        </Grid>


                        <Grid item lg={4} sm={4} xs={6} sx={{mt: 6}}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >

                                {REDES.map((item, index) => {
                                    return (
                                        <Grid key={index} item lg={4} sm={4} xs={4}>
                                            <ButtonBase
                                                onClick={() => irUrl(item.url)}
                                                sx={{borderRadius: 2}}>
                                                <item.Icono size={'2.5rem'} variant={"Bold"} color={'#fff'}/>
                                            </ButtonBase>
                                        </Grid>
                                    )
                                })}

                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>

                <Grid item lg={3} sm={5} xs={12}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                    >

                        <Grid item lg={12} sm={12} xs={12} sx={{marginTop: sCell ? 2 : 0}}>
                            <Typography sx={{
                                fontFamily: 'CitrusGothic-Regular',
                                fontSize: sCell ? 32 : 40,
                                color: PRIMARIO,
                            }}>
                                HORARIOS

                            </Typography>
                        </Grid>


                        <Grid item lg={12} sm={12} xs={12} sx={{marginTop: sCell ? 1 : 0}}>
                            <Typography sx={{
                                fontSize: 18,
                                fontWeight: 500,
                                color: '#fff',
                            }}>
                                {CONTACTOS[3].contenido}

                            </Typography>
                        </Grid>


                    </Grid>
                </Grid>

                <Grid item lg={1} sm={12} xs={12}>

                </Grid>


                <Grid item container lg={3} sm={12} xs={12} sx={{justifyContent: "center", marginTop: sCell ? 2 : 0}}>
                    <img src={logo} width={sCell ? 200 : 250} height={'auto'} alt={'Logo picanteria Ipiales'}
                         title={'Logo picanteria Ipiales'}/>
                </Grid>


            </Grid>

            <Grid item container lg={12} sm={12} xs={12}
                  sx={{justifyContent: "center", backgroundColor: PRIMARIO, py: 2}}>
                <Typography sx={{color: '#fff'}}> © Copyright 2023 - 2024 | Picanteria Ipiales</Typography>

            </Grid>


        </Grid>
    )
}

export default Footer;