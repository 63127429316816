/**************************************************
 * Nombre:       Parqueadero_Gratuito
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Button, Grid, Typography, useMediaQuery} from "@mui/material";
import {theme} from "../../../Tema";
import {ACENTO, COLORSUAVE, LINKMAP, LINKPARQUEADERO} from "../../../Constantes";
import ic_carro from '../../../Recursos/ic_carro.svg'
import {irUrl} from "../../../Utilidades/irUrl";
import fondo from "../../../Recursos/natural-paper.png";

const Parqueadero_Gratuito = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const sTab = useMediaQuery(theme.breakpoints.only("sm"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            sx={{backgroundColor: '#F0EFD8', backgroundImage: `url(${fondo})`, pb: 18}}
        >

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{maxWidth: '1400px', px: 3}}
            >

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center'}}>
                    {sCell &&

                        <img src={ic_carro} width={80} height={'auto'}/>

                    }
                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: sCell ? 2 : 0}}>
                    <Typography
                        sx={{fontSize: sCell ? 16 : 28, color: theme.palette.primary.main, fontWeight: 900}}>DOMINGOS
                        Y FESTIVOS</Typography>
                </Grid>

                <Grid item container lg={12} sm={12} xs={12}
                      sx={{justifyContent: 'center', marginTop: sCell ? -1 : -2}}>
                    {!sCell &&
                        <span>
                        <img src={ic_carro} width={sCell ? 40 : 70} height={'auto'}
                             style={{marginTop: sCell ? 2 : 10, marginRight: sCell ? 10 : 20}}/>
                    </span>}
                    <Typography
                        sx={{
                            fontSize: sCell ? 38 : 64,
                            color: ACENTO,
                            fontWeight: 900,
                            fontFamily: 'CitrusGothic-Regular',
                        }}>
                        Parqueadero gratuito
                    </Typography>
                    {!sCell &&
                        <span>
                                    <img src={ic_carro} width={sCell ? 40 : 70} height={'auto'}
                                         style={{marginTop: sCell ? 2 : 10, marginLeft: sCell ? 10 : 20}}/>
                    </span>
                    }

                </Grid>

                <Grid item container lg={12} sm={12} xs={12} sx={{
                    justifyContent: 'center', marginTop: sCell ? 0 :
                        -1
                }}>
                    <Typography
                        sx={{
                            fontSize: sCell ? 16 : 22,
                            color: theme.palette.primary.main,
                            fontWeight: 500,
                            textAlign: 'center',
                            lineHeight: 1.1
                        }}>
                        Parqueadero "LA ESQUINA" ubicado en la calle 19 carrera 23 esquina
                    </Typography>
                </Grid>


                <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: 'center', marginTop: 4}}>
                    <Button
                        onClick={() => irUrl(LINKPARQUEADERO)}
                        color={'primary'} sx={{color: theme.palette.secondary.main}}>Como llegar</Button>
                </Grid>

            </Grid>

        </Grid>
    )

}
export default Parqueadero_Gratuito