/**************************************************
 * Nombre:       Tarjeta_Producto
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {ButtonBase, Grid, Typography, useMediaQuery} from "@mui/material";
import fondo from '../../Recursos/fondo_imagen.png'
import logo from '../../Recursos/img_defecto.png'
import {ACENTO, COLORSUAVE, NUMWASS, PRIMARIO} from "../../Constantes";
import {formatoMoneda} from "../../Utilidades/formatoMoneda";
import {iniciarChatWhatsApp} from "../../Utilidades/iniciarChatWhatsApp";
import {theme} from "../../Tema";
import {irUrl} from "../../Utilidades/irUrl";


const Tarjeta_Producto = ({valores, click}) => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const sTab = useMediaQuery(theme.breakpoints.only("sm"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{marginTop: sCell ? 8 : 4}}
        >

            <Grid item lg={12} sm={5} xs={12}
                  sx={{
                      backgroundImage: `url(${fondo})`,
                      backgroundSize: 'cover',
                      marginTop: sCell ? -10 : 0,
                      zIndex: 4
                  }}
            >


                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        maskImage: `url(${fondo})`,
                        maskRepeat: 'no-repeat',
                        maskSize: '100%',
                        maskBorder: `url(${fondo}) 100`,

                    }}>


                    <img src={valores.img ? valores.img : logo} width={'98%'} height={'auto'}/>

                </Grid>


            </Grid>

            <Grid item lg={12} sm={7} xs={12} sx={{pl: 2, marginTop: sTab ? 0 : -10, zIndex: 2}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{backgroundColor: '#fff', borderRadius: 2, p: 2,}}
                >


                    <Grid item lg={12} sm={12} xs={12} container justifyContent="center"
                          sx={{marginTop: sTab ? 1 : 5}}
                    >
                        <Typography sx={{
                            fontSize: sCell ? 18 : 22,
                            fontFamily: 'CitrusGothic-Regular',
                            color: PRIMARIO,
                            lineHeight: 1.1,
                            textAlign: 'center'
                        }}>{valores.nombre}</Typography>
                    </Grid>


                    <Grid item lg={12} sm={12} xs={12} sx={{mb: 1, pb: 2}} container justifyContent="center">
                        <Typography sx={{
                            fontSize: sCell ? 11 : 13,
                            color: ACENTO,
                            textAlign: 'center',
                            height: 40
                        }}>
                            {valores.descripcion}
                        </Typography>
                    </Grid>

                    <Grid item lg={6} sm={6} xs={6} container justifyContent="center">
                        <Typography sx={{
                            fontSize: sCell ? 16 : 20,
                            fontFamily: 'CitrusGothic-Regular',
                            color: ACENTO
                        }}>{formatoMoneda(valores.precio)}</Typography>
                    </Grid>

                    <Grid item lg={6} sm={6} xs={6} container justifyContent="center">
                        <ButtonBase sx={{borderRadius: 2, backgroundColor: PRIMARIO, px: 2, py: 0.4}}
                                    onClick={() => iniciarChatWhatsApp({num: NUMWASS, tema: valores.nombre})}
                        >
                            <Typography sx={{
                                fontSize: sCell ? 16 : 18,
                                fontFamily: 'CitrusGothic-Regular',
                                color: '#fff'
                            }}>PEDIR POR CHAT</Typography>
                        </ButtonBase>
                    </Grid>


                </Grid>
            </Grid>

        </Grid>
    )
}

export default Tarjeta_Producto;