/**************************************************
 * Nombre:       Ho_ManejoTargetas
 * Descripcion:
 *
 * Libreria:  react-infinite-scroll-component, @mui/material
 *
 * Tiempo:     1 Hr
 **************************************************/
import {Grid, useMediaQuery} from "@mui/material";
import {theme} from '../../Tema'


export const Ho_ManejoTargetas = ({
                                      Card,
                                      data = [],
                                      click,
                                      lg = 12,
                                      sm = 12,
                                      xs = 12,
                                      spacing = 0,
                                      setEscogidos = null,
                                      escogidos = [],
                                      px = 3,
                                      difiere = false,
                                      pxCell = 0
                                  }) => {

    const sCell = useMediaQuery(theme.breakpoints.only("xs"));

    const adicionarItem = (e) => {
        if (setEscogidos !== null) {
            setEscogidos((arr) => arr.concat(e))
        }

    }

    const quitarItem = (e) => {

        let arr = escogidos;
        if (setEscogidos !== null) {
            setEscogidos([])

            for (let i = 0; i < arr.length; i++) {
                if (arr[i].id !== e.id) {
                    setEscogidos((a) => a.concat(arr[i]))
                }
            }
        }

    }

    const funEscoger = (e, check) => {
        if (check) {
            adicionarItem(e)
            console.log(e)
        } else {
            quitarItem(e)
        }
    }


    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
        >


            <Grid

                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                sx={{maxWidth: "1400px", px: sCell ? pxCell : px}}
            >


                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={spacing}
                >


                    {data.map((item, index) => {
                        return (
                            <Grid item key={index} lg={lg} sm={sm} xs={xs}>
                                <Card valores={item} click={click} funEscoger={funEscoger} index={index}
                                      difiere={difiere}/>
                            </Grid>
                        )
                    })}

                </Grid>

            </Grid>


        </Grid>
    )
}