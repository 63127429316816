/**************************************************
 * Nombre:       Pagina
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid, useMediaQuery} from "@mui/material";
import Home from "./Secciones/Home";
import Barra from "./Barra";
import BarraHamburguer from "./Barra/BarraHamburguer/BarraHamburguer";
import {theme} from '../Tema'



const Pagina = () => {
    const sCell = useMediaQuery(theme.breakpoints.only("xs"))
    const sTab = useMediaQuery(theme.breakpoints.only("sm"))
    const masSM = useMediaQuery(theme.breakpoints.up("md"))


    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >


            {masSM ? <Barra secciones={secciones}/>
                : <BarraHamburguer secciones={secciones}/>
            }

            <Grid item lg={12} sm={12} xs={12}>
                <Home/>
            </Grid>

        </Grid>
    )
}

export default Pagina;

const secciones = ['Inicio', 'Contactos', 'Menu', 'Testimonios']